import { Box } from "@@panda/jsx";
import { ResultOf } from "@internal/gql/client";
import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/display/Typography/Typography";
import { StatusPill } from "components/main/StatusPill";
import { formatInstrumentTypename } from "screens/deals/utils";
import { formatIsoDate } from "utility/dates";

import { CompanyCell } from "../CompanyCell/CompanyCell";
import { LeadCell } from "../LeadCell/LeadCell";
import { invitedDealsQuery } from "./useInvitedDeals";

export enum Column {
  Company = "company",
  Commitment = "commitment",
  Status = "status",
  Lead = "lead",
  TargetClose = "close",
}

export type InvitedDeal = ResultOf<
  typeof invitedDealsQuery
>["invitedDeals"][number];

const columnHelper = createColumnHelper<InvitedDeal>();

export const columns = [
  columnHelper.accessor("tradeName", {
    id: Column.Company,
    header: "Company",
    enableSorting: true,
    cell: (props) => (
      <CompanyCell
        name={props.row.original.tradeName}
        logo={props.row.original.logoUrl}
        type={formatInstrumentTypename(
          props.row.original.instrument.__typename
        )}
      />
    ),
  }),
  columnHelper.accessor("investment.commitment.amount", {
    id: Column.Commitment,
    header: "Your commitment",
    sortingFn: (a, b, colId) => {
      return (a.getValue(colId) || 0) < (b.getValue(colId) || 0) ? 1 : -1;
    },
    enableSorting: true,
    cell: (props) => {
      const amount = props.row.original.investment?.commitment?.amount ?? 0;
      const fmt = props.row.original.investment?.commitment?.formatted ?? "";
      return (
        <Typography
          variant="body"
          color={amount ? "white" : "grey.gunsmoke"}
          textAlign={{ base: "right", lg: "left" }}
        >
          {amount ? fmt : "–"}
        </Typography>
      );
    },
  }),
  columnHelper.display({
    id: Column.Status,
    header: "Status",
    enableSorting: false,
    cell: (props) => (
      <Box
        w="full"
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <StatusPill
          status={props.row.original.investment?.status || "PASSED"}
          w="auto"
        />
      </Box>
    ),
  }),
  columnHelper.accessor("lead.displayName", {
    id: Column.Lead,
    header: "Lead",
    enableSorting: true,
    cell: (props) => (
      <LeadCell name={props.row.original.lead?.displayName ?? ""} />
    ),
  }),
  columnHelper.accessor("targetCloseDate", {
    id: Column.TargetClose,
    header: "Close date",
    enableSorting: true,
    cell: (props) => {
      const date = props.row.original.targetCloseDate;
      return (
        <Typography variant="body" color="grey.gunsmoke">
          {date ? formatIsoDate(date) : "–"}
        </Typography>
      );
    },
  }),
];
