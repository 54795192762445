import { css } from "@@panda/css";

export const tableStyles = {
  header: css({
    paddingY: "m",
    width: { base: "25%", lg: "15%" },
    paddingRight: "m",
    color: "grey.gunsmoke",
    textAlign: "left",
    whiteSpace: "nowrap",
    fontWeight: "normal",
  }),
  row: css({
    position: "relative",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderColor: "grey.dune",
  }),
  cell: css({
    paddingY: "l",
    paddingRight: { base: "xs", md: "m" },
    overflow: "hidden",
    textTransform: "capitalize",
  }),
  sorting: css({
    cursor: "pointer",
    userSelect: "none",
  }),
  util: {
    wideCol: css({
      width: { base: "70%", lg: "15rem" },
    }),
    wideColx2: css({
      width: { base: "40%", lg: "15rem", xl: "20rem" },
    }),
    hiddenSm: css({
      display: { base: "none", lg: "table-cell" },
    }),
    lastCellOnSm: css({
      textAlign: { base: "right", lg: "left" },
      paddingRight: { base: "0!", lg: "m" },
    }),
    alignRightStyle: css({
      textAlign: "right",
    }),
  },
};
