import { HStack, HstackProps } from "@@panda/jsx";
import { DealStatus, InvestmentStatus } from "@internal/gql/generated/types";

import { pillRecipe } from "./styles";
import { computeStatusText, mapDealStatusToStyleVariant } from "./utils";

interface Props extends HstackProps {
  status: DealStatus | InvestmentStatus;
}

export function StatusPill({ status, ...rest }: Props) {
  const styles = pillRecipe({
    status: mapDealStatusToStyleVariant(status),
  });

  return (
    <HStack className={styles.container} {...rest}>
      <div className={styles.icon} />
      <p className={styles.text}>{computeStatusText(status)}</p>
    </HStack>
  );
}
