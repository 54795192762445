import { css, cx } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import { Styled } from "components/styled";
import { HTMLAttributes } from "react";

import { tableStyles } from "./styles";

interface HeaderProps extends HTMLAttributes<HTMLTableCellElement> {
  sorting?: "asc" | "desc" | false;
  canSort?: boolean;
  hiddenSm?: boolean;
  alignRight?: boolean;
  isFirst?: boolean;
}

export function TableHeader({
  children,
  className,
  hiddenSm = false,
  alignRight = false,
  canSort = false,
  isFirst = false,
  sorting,
  ...rest
}: HeaderProps) {
  const sortDisabled = sorting === false;

  const SortIcon =
    sorting === "asc" || sortDisabled
      ? // when sorting is disable we still want the arrow to be up when the icon is shown on hover
        ArrowUpIcon
      : ArrowDownIcon;

  const sort = (
    <SortIcon
      width="1rem"
      color={token("colors.grey.alabaster")}
      className={css({
        display: canSort ? "block" : "none",
        opacity: sortDisabled ? 0.2 : 1,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        transition: ".1s ease-in-out",
        ...(alignRight ? { left: "-20px" } : { right: "-20px" }),
      })}
    />
  );

  return (
    <Styled.th
      className={cx(
        tableStyles.header,
        hiddenSm ? tableStyles.util.hiddenSm : null,
        alignRight ? tableStyles.util.alignRightStyle : null,
        canSort && sorting !== undefined ? tableStyles.sorting : null,
        isFirst ? css({ width: { base: "40%!", lg: "22%!" } }) : undefined,
        className
      )}
      {...rest}
    >
      <Box position="relative" w="auto" display="inline-block">
        {alignRight ? sort : null}
        <div>{children}</div>
        {!alignRight ? sort : null}
      </Box>
    </Styled.th>
  );
}
