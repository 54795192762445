import { graphql, useGraphql } from "@internal/gql/client";
import { withStableArrayFallback } from "@internal/utils/funcs/withStableArrayFallback";
import { useRouter } from "next/router";
import { seconds } from "utility/seconds";

export function usePassedDeals() {
  const router = useRouter();
  const { data, isSuccess, error } = useGraphql(passedDealsQuery, {
    options: {
      staleTime: seconds(5),
    },
  });

  return {
    ready: isSuccess && router.isReady,
    error,
    data: withStableArrayFallback(data?.passedDeals),
  };
}

export const passedDealsQuery = graphql(`
  query GetPassedDeals {
    passedDeals {
      __typename
      ... on Deal {
        id
        tradeName
        closedDate
        status
        logoUrl
        slug
        syndicate {
          __typename
          id
          slug
        }

        ... on Primary {
          instrument {
            __typename
          }
        }
        ... on Secondary {
          instrument {
            __typename
          }
        }

        lead {
          ... on Syndicate {
            __typename
            id
            displayName
          }
          ... on Founder {
            __typename
            id
            displayName
          }
        }
      }
    }
  }
`);
