import { DealStatus, InvestmentStatus } from "@internal/gql/generated/types";
import { exhaustiveCheck } from "@internal/utils/types";

import { PillVariants } from "./styles";

export function mapDealStatusToStyleVariant(
  status: DealStatus | InvestmentStatus
): PillVariants["status"] {
  switch (status) {
    // Deal status
    case "LIVE":
      return "green";
    case "CLOSED":
      return "grey";
    case "CANCELLED":
      return "grey";
    case "DRAFT":
      return "yellow";
    case "REVIEW":
      return "purple";
    case "CALCULATING_FEES":
    case "AWAITING_LEGALS":
    case "REVIEWING_LEGALS":
    case "WIRING_FUNDS":
    case "CLOSING":
      return "orange";
    // Investment status
    case "COMMITMENT_REQUIRED":
    case "TERMS_ACCEPTANCE_REQUIRED":
    case "PAYMENT_REQUIRED":
      return "yellow";
    case "TRANSFER_INITIATED":
      return "orange";
    case "PAYMENT_RECEIVED":
    case "COMPLETED":
      return "green";
    // Common
    case "PASSED":
      return "grey";
    default:
      return exhaustiveCheck(status, { fallback: "grey" });
  }
}

export function computeStatusText(status: DealStatus | InvestmentStatus) {
  switch (status) {
    // Deal status
    case "LIVE":
      return "Live";
    case "CLOSED":
      return "Closed";
    case "CANCELLED":
      return "Cancelled";
    case "DRAFT":
      return "Draft";
    case "REVIEW":
      return "In Review";
    case "CALCULATING_FEES":
      return "Calculating fees";
    case "AWAITING_LEGALS":
      return "Awaiting legals";
    case "REVIEWING_LEGALS":
      return "Reviewing legals";
    case "WIRING_FUNDS":
      return "Wiring funds";
    case "CLOSING":
      return "Closing";
    // Investment status
    case "COMMITMENT_REQUIRED":
      return "Commitment required";
    case "TERMS_ACCEPTANCE_REQUIRED":
      return "Terms acceptance required";
    case "PAYMENT_REQUIRED":
      return "Payment required";
    case "TRANSFER_INITIATED":
      return "Transfer initiated";
    case "PAYMENT_RECEIVED":
      return "Payment received";
    case "COMPLETED":
      return "Invested";
    case "PASSED":
      return "Passed";
    default:
      return exhaustiveCheck(status, { fallback: "Unknown" });
  }
}
