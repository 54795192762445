import { ResultOf } from "@internal/gql/client";
import {
  DealDocuments,
  DealInvestorDocuments,
  DealLeadDocuments,
  GetPortfolioDealsQuery,
} from "@internal/gql/generated/types";

import { portfolioDealsQuery } from "./usePortfolioDeals";

type Documents = GetPortfolioDealsQuery["portfolioDeals"][0]["documents"];
type DocKey = keyof DealLeadDocuments | keyof DealInvestorDocuments;

export function formatDealDocuments(docs: Documents) {
  if (!docs) return [];

  return Object.keys(docs)
    .filter((k) => {
      const key = k as DocKey;
      return key !== "__typename" && key !== "defaultDealSheet";
    })
    .map((doc) => {
      const key = doc as unknown as keyof Required<DealDocuments>;
      return {
        label: parseDocumentLabel(key),
        url: docs[key],
      };
    });
}

function parseDocumentLabel(
  doc: keyof DealLeadDocuments | keyof DealInvestorDocuments
) {
  switch (doc) {
    case "defaultDealSheet":
      return "Default deal sheet";
    case "dealSheetDocument":
      return "Deal sheet";
    case "syndicateTermsDocument":
      return "Syndicate terms";
    case "proofOfInvestmentDocument":
      return "Proof of investment";
    case "investmentAgreementDocument":
      return "Investment agreement";
    case "platformAgreementDocument":
      return "Platform agreement";
    default:
      return "Unknown document";
  }
}

export function computeAccountAdminOfDeal(
  accountId: string,
  deal: ResultOf<typeof portfolioDealsQuery>["portfolioDeals"][number]
) {
  if (deal.lead?.__typename === "Founder") {
    return accountId === deal.lead?.id;
  }

  return deal.syndicate?.members?.members.some((m) => {
    return m.role === "LEAD" && m.id === accountId;
  });
}
