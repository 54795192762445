import { Box, Flex, HStack } from "@@panda/jsx";
import Image from "next/image";
import Marquee from "react-fast-marquee";

/*
 the logos have a hover effect that increases their size slightly
 when using transform scale, the images become blurry (because the browser is trying to scale the image up itself)
 instead we are simply increasing the images width/height on hover
 we are also increasing the width/height of the image container to prevent the logos causing each other to move
 */

const logoSize = 96; // px
const logoMagnifyBy = 1.1;
const logosGap = 68; // px

const extraSpace = logoSize * logoMagnifyBy - logoSize;
const calculatedLogosGap = `${logosGap - extraSpace}px`;

export function LogoMarquee() {
  return (
    <Marquee
      direction="right"
      style={{
        padding: "2.5rem 0px 3.75rem",
        height: "11.25rem",
        maskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 15%, rgb(0, 0, 0) 85%, rgba(0, 0, 0, 0) 100%)",
      }}
      autoFill
    >
      <HStack gap={calculatedLogosGap} marginRight={calculatedLogosGap}>
        {imageUrls.map((url) => (
          <Flex
            listStyleType="none"
            justifyContent="center"
            alignItems="center"
            key={url}
            width={`${logoSize * logoMagnifyBy}px`}
            height={`${logoSize * logoMagnifyBy}px`}
          >
            <Box
              key={url}
              boxShadow="0px 0px 10px -5px rgba(255, 255, 255, 0.14), 0px 0px 25px -5px rgba(255, 255, 255, 0.30)"
              borderRadius="3xl"
              overflow="hidden"
              transition="0.2s ease-in-out"
              width={`${logoSize}px`}
              height={`${logoSize}px`}
              position="relative" // important for <Image fill /> to work
              userSelect="none"
              _hover={{
                width: `${logoSize * logoMagnifyBy}px`,
                height: `${logoSize * logoMagnifyBy}px`,
              }}
            >
              <Image
                draggable={false}
                quality={100}
                src={url}
                key={url}
                fill
                alt="Example Company"
              />
            </Box>
          </Flex>
        ))}
      </HStack>
    </Marquee>
  );
}

const imageUrls = [
  "/community/stripe.webp",
  "/community/spacex.webp",
  "/community/openai.webp",
  "/community/discord.webp",
  "/community/plaid.webp",
  "/community/notion.webp",
  "/community/linear.webp",
  "/community/revolut.webp",
  "/community/klarna.webp",
];
