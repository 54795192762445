import { graphql, useGraphql } from "@internal/gql/client";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { seconds } from "utility/seconds";

import { sortInvitedDeals } from "./helpers";

export function useInvitedDeals() {
  const router = useRouter();
  const { data, isSuccess, error } = useGraphql(invitedDealsQuery, {
    options: {
      staleTime: seconds(5),
    },
  });

  // https://tanstack.com/table/latest/docs/guide/data#give-data-a-stable-reference
  const invitedDeals = useMemo(() => {
    return sortInvitedDeals(data?.invitedDeals);
  }, [data]);

  return {
    ready: isSuccess && router.isReady,
    error,
    data: invitedDeals,
  };
}

export const invitedDealsQuery = graphql(`
  query GetInvitedDeals {
    invitedDeals {
      __typename
      ... on Deal {
        id
        tradeName
        targetCloseDate
        logoUrl
        slug

        syndicate {
          __typename
          id
          slug
        }

        ... on Primary {
          instrument {
            __typename
          }
        }
        ... on Secondary {
          instrument {
            __typename
          }
        }

        investment {
          __typename
          id
          status
          commitment {
            __typename
            formatted
            amount
          }
        }

        lead {
          ... on Syndicate {
            __typename
            id
            displayName
          }
          ... on Founder {
            __typename
            id
            displayName
          }
        }
      }
    }
  }
`);
