import { css, cx } from "@@panda/css";
import { Styled } from "components/styled";
import Link from "next/link";
import { HTMLAttributes } from "react";
import { rowZIndex } from "screens/deals/utils";

import { tableStyles } from "./styles";

interface CellProps extends HTMLAttributes<HTMLTableCellElement> {
  dealLinkText: string;
  dealUrl: string;
  hiddenSm?: boolean;
  alignRight?: boolean;
  testId?: string;
}

export function TableCell({
  children,
  className,
  testId,
  dealLinkText,
  dealUrl,
  hiddenSm = false,
  alignRight = false,
  ...rest
}: CellProps) {
  return (
    <Styled.td
      data-testid={testId}
      position="relative"
      className={cx(
        tableStyles.cell,
        hiddenSm ? tableStyles.util.hiddenSm : null,
        alignRight ? tableStyles.util.alignRightStyle : null,
        className
      )}
      {...rest}
    >
      {children}
      <Link
        className={css({
          position: "absolute",
          cursor: "pointer",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          opacity: 0.1,
          bgColor: "transparent",
        })}
        aria-label={dealLinkText}
        href={dealUrl}
        // important to not clash with <LeadCell /> and <FilesModal />
        style={{ zIndex: rowZIndex }}
      />
    </Styled.td>
  );
}
